<template>
	<div class="wrap">
		<div class="top">
			<span @click="handleClick">立即开通<i class="el-icon-arrow-right" /></span>
		</div>
		<div class="content">
			<div class="left"></div>
			<ul class="list">
				<li>
					<div class="list-icon"></div>
					<h5>免费流量</h5>
					<p>9亿微信用户</p>
					<p>微信搜索</p>
					<p>附近查找</p>
					<p>分享引流</p>
				</li>
				<li>
					<div class="list-icon"></div>
					<h5>超低门槛</h5>
					<p>消毒设备网提供技术</p>
					<p>操作简单</p>
					<p>用完即走</p>
					<p>方便快捷</p>
				</li>
				<li>
					<div class="list-icon"></div>
					<h5>功能强大</h5>
					<p>地图定位</p>
					<p>门店系统</p>
					<p>咨询系统</p>
					<p>商品展示</p>
				</li>
				<li>
					<div class="list-icon"></div>
					<h5>安全稳定</h5>
					<p>依托微信平台</p>
					<p>性能安全稳定</p>
					<p>浏览顺畅</p>
					<p>优质体验</p>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
	export default {
		methods: {
			handleClick() {
				this.$axios.post('/applyService', {
					project: '微信小程序'
				}).then(data => {
					this.$alert('申请成功，我们的客户经理将于1个工作日内与您联系！', '微信小程序', {
						type: 'success'
					})
				})
			}
		}
	}
</script>

<style scoped>
	.wrap {
		width: 1600px;
		background: #f8f8f8;
	}

	.top {
		height: 399px;
		background: url(../assets/img/applet-bg1.png);
		background-size: 100% 100%;
		position: relative;
	}

	.top span {
		position: absolute;
		width: 182px;
		height: 52px;
		font-size: 24px;
		color: #b18c22;
		line-height: 52px;
		text-align: center;
		background: linear-gradient(to bottom right, #f6e0a9, #f5d98e, #f4d377, #f4d06d);
		border-radius: 5px;
		bottom: 58px;
		left: 50%;
		transform: translateX(-50%);
	}

	.content {
		display: flex;
		/* justify-content: center; */
		margin-top: 93px;
		padding: 0 251px;
		align-items: center;
	}

	.left {
		width: 341px;
		height: 610px;
		background: url(../assets/img/applet-bg2.png);
		background-size: 100% 100%;
		flex: none;
	}

	.list {
		display: flex;
		text-align: center;
		flex: 1;
		overflow: hidden;
		justify-content: space-around;
	}

	.list-icon {
		width: 111px;
		height: 111px;
		background-size: 100% 100%;
		margin: 0 auto;
	}

	.list li:nth-of-type(1) .list-icon {
		background-image: url(../assets/img/applet-icon1.png);
	}

	.list li:nth-of-type(2) .list-icon {
		background-image: url(../assets/img/applet-icon2.png);
	}

	.list li:nth-of-type(3) .list-icon {
		background-image: url(../assets/img/applet-icon3.png);
	}

	.list li:nth-of-type(4) .list-icon {
		background-image: url(../assets/img/applet-icon4.png);
	}

	.list h5 {
		color: #333;
		font-size: 28px;
		height: 52px;
		line-height: 52px;
		margin-top: 13px;
	}

	.list p {
		line-height: 50px;
		height: 50px;
		font-size: 22px;
		color: #666666;
	}
</style>
